// firebase/index
export const firebaseConfig = {
  apiKey: "AIzaSyD-1uMcwcKeRfCsIHWWe90NEyG9r1GNPp4",
  authDomain: "innovaturba-8677a.firebaseapp.com",
  databaseURL: "https://innovaturba-8677a.firebaseio.com",
  projectId: "innovaturba-8677a",
  storageBucket: "innovaturba-8677a.appspot.com",
  messagingSenderId: "885172346195",
  appId: "1:885172346195:web:1cd1086890c196f3f195a6",
  measurementId: "G-K5FK7W3C8V"
}

// firebase/storage
export const storageUrl = 'https://firebasestorage.googleapis.com/v0/b/innovaturba-8677a.appspot.com/o/'